import React from "react"
import { Link } from "gatsby"
import {RiArrowLeftSLine, RiCheckboxCircleLine} from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Thanks = () => (
  <Layout className="thanks-page">
    <SEO title="Thank you"/>
    <div className="wrapper" style={{
      textAlign: "center"
    }}>
      <RiCheckboxCircleLine style={{
        fontSize: "64px",
        color: "var(--primary-color)"
      }}/>
      <h1 style={{
        fontSize: "2.25rem",
        marginBottom: "1rem"
      }}>Got your message</h1>
      <p style={{
        fontSize: "1rem",
        marginBottom: "2rem"
      }}>Thank you for getting in touch us. We will get back to you shortly.</p>
      <Link to="/" className="button" style={{ fontSize: "1rem" }}><RiArrowLeftSLine className="icon -left"/>Lets go back to Homepage</Link>
    </div>

  </Layout>
)

export default Thanks